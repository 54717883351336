import { getSupplyTaskitemPage } from '@/api/msp/taskitem'
const getDefaultState = () => {
  return {
    taskQuery: {
      pageNumber: 1,
      pageSize: 15
    },
    taskData: [],
    selectedTaskItemIds: [],
    tempSelectedItem: [], // 临时存储分页选中项
    curTaskInfo: {}
  }
}
const state = getDefaultState()
const supplierWorkOrder = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_task_query (state, data) {
      state.taskQuery = data
    },
    set_task_data (state, data) {
      state.taskData = data
    },
    set_selected_task_item_ids (state, data) {
      state.selectedTaskItemIds = data
    },
    set_temp_selected_item (state, data) {
      state.tempSelectedItem = (data instanceof Array) ? data : []
    },
    set_cur_task_info (state, data) {
      state.curTaskInfo = data
    }
  },
  actions: {
    /**
     * 供应商获取任务数据
     * @param {Object} param0
     * @param {Object} query
     */
    getSupplierTaskData ({ state, commit }, query) {
      const newQuery = Object.assign(state.taskQuery, query)
      commit('set_task_query', newQuery)
      return new Promise((resolve) => {
        getSupplyTaskitemPage(newQuery).then(res => {
          if (res && !res.errcode) {
            commit('set_task_data', res)
          } else {
            commit('set_task_data', [])
          }
          resolve(res)
        })
      })
    }
  }
}

export default supplierWorkOrder
