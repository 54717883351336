<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          :serviceType="2"
        ></component>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import supplierWorkOrderModule from '@/store/modules/workOrder_supplier'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧组件
    SetWorkgroup: () => import('./components/left/SetWorkgroup'),
    SetFiles: () => import('./components/left/SetFiles'),
    // 底部组件
    IndexTable: () => import('./components/bottom/IndexTable'),
    CancelTaskTable: () => import('./components/bottom/CancelTaskTable'),

    // 右侧组件
    TaskTypeStatistic: () => import('./components/right/TaskTypeStatistic')
  },
  mounted () {
    if (!this.$store.hasModule('supplierWorkOrder')) {
      this.$store.registerModule('supplierWorkOrder', supplierWorkOrderModule)
    }
    this.init()
  },
  methods: {
    init () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(true)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('IndexTable')
      this.setRightComponent('TaskTypeStatistic')
    }
  },
  destroyed () {
    this.$store.unregisterModule('supplierWorkOrder')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
